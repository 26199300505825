<template>
    <div class="shop-list">
    </div>
  </template>
  
  <script lang="js">
  import { defineComponent, reactive, ref, computed, onUnmounted } from 'vue'
  import { COMPANY_ID } from '@/common/constant'
  import { useRouter } from 'vue-router'
  import store from '@/store'
  import dayjs from 'dayjs'
  import emitter from '@/utils/eventbus'
  import { ElMessage } from 'element-plus'
  import { meiTuanBackAuthorization,getAuthUrl } from '@/service/main/shop'
  export default defineComponent({
    props: {},
    components: {
    },
    name: "empower",
    setup() {
      const router = useRouter()
      const getQuery = () => {
        var url = window.location.href;
        console.log("url",url);
        //切割URL字符串，获取参数auth_code和redirect_url
        let stringCode;
        if(url.indexOf('?') != -1){
          let arr = url.split('?')
          stringCode = arr[1]
        }
        console.log("stringCode",stringCode);
        if(stringCode)  {
          meiTuanBackAuthorization(stringCode).then(res => {  //把两个参数传给接口
              //授权成功
              if (res.code==0) {
                ElMessage({
                  message: '绑定成功!',
                  type: 'success'
                })
                router.replace({
                  path: '/main/bindshop/list'
                })
              }else{
                ElMessage({
                  message: res.msg,
                  type: 'error'
                })
              }
          })
        }
      }
      const initPage = () => {
        var url = window.location.href
        if(url.indexOf('code') != -1){
          getQuery()
        }else{
          getAuthUrl().then(res=>{
            console.log("res",res.data);
            window.location.href = res.data
          })
          // window.location.href = `https://e.dianping.com/dz-open/merchant/auth?app_key=${appKey}&state=true&redirect_url=${encodeURIComponent(url)}`
        }
      }
      initPage()
  
      return {
        COMPANY_ID,
        router,
        dayjs,
        getQuery
      }
    }
  })
  </script>
  
  <style scoped lang="less">
  </style>
  